import React from "react"
import styled from "styled-components"
import ServicesBoxBody from "./servicesBoxBody"
import Text, { TextType } from "./text"

const HeaderP = styled.p`
    color: ${props => props.theme.main};
    text-align: left;
`

const UnternehmensberatungServiceBoxBody = () => (
    <ServicesBoxBody >
        <Text type={TextType.SubSubHeader}>
            <HeaderP>
                Erfolgreich werden, erfolgreich bleiben! - Mit unserer individuellen Unternehmensberatung
            </HeaderP>
        </Text>
        <Text type={TextType.Body}>
            <p>
                Durch eine sorgfältige Analyse Ihres Unternehmens spüren wir rasch Problembereiche in Ihrem Unternehmen auf.
                Dies geschieht unter Beachtung wesentlicher betriebswirtschaftlicher Prinzipien.
            </p>
            <p>
                Der Wettbewerb unter den Unternehmen wird stetig größer. Deshalb ist es wichtig, dass Sie sich einen Vorsprung verschaffen!
                Mit unserer wirtschaftlichen und rechtlichen Unternehmensberatung liefern wir Ihnen die passenden Lösungen.
            </p>
            <p>
                <Text type={TextType.Body} bold>Sie brauchen keinen Berater, sondern einen Partner!</Text>
            </p>
            <p>
                Und genau das sind wir für Sie. Ihre Ziele sind unsere Motivation. Zu deren Erreichung setzen wir all unsere Erfahrungen und unser Wissen ein.
                Gemeinsam planen wir grundlegende unternehmerische Entscheidungen und finden die rentabelsten Lösungen für Ihr Unternehmen.
            </p>
            <p>
                Gründliche und weitsichtige Planung bildet die Basis Ihres Erfolges.
                Wir sind zwar keine Wahrsager, haben jedoch die richtigen Instrumente, um in Ihre Unternehmenszukunft zu blicken! Diese stellen wir Ihnen gerne zur Verfügung.
            </p>
        </Text>
    </ServicesBoxBody>
)

export default UnternehmensberatungServiceBoxBody
