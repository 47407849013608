import React from "react"
import styled from "styled-components"
import {
  ArbeitnehmerOnlineBodyRightIcon,
  ArrowIcon,
  CloudExchangeIcon,
  DocumentIcon,
} from "../icons/arbeitnehmerOnlineIcons"
import { HouseIcon } from "../icons/unternehmenOnlineBodyIcons"
import { laptop } from "../util/mediaQueries"
import ServicesBoxBody from "./servicesBoxBody"
import Text, { TextType } from "./text"
import { Media } from "gatsby-plugin-fresnel"

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const GraphicDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${laptop} {
    width: 70%;
  }
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`

const GraphicMiddleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
`

const HeaderP = styled.p`
  color: ${props => props.theme.main};
  text-align: left;
`
const StyledUl = styled.ul`
  margin: 0;
  padding-left: 20px;
  margin-top: 20px;
`

const StyledLi = styled.li`
  font-size: 14px;
  margin-bottom: 20px;
`

const ColoredSpan = styled.span`
  color: ${props => props.theme.main};
`

const GraphicInnerIconDiv = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`

const GraphicIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GraphicIconTextDiv = styled.div`
  margin-top: 10px;
`

const Graphic = ({ outerIconSize, innerIconSize, arrowWidth, textType }) => {
  return (
    <GraphicDiv>
      <GraphicIconDiv>
        <HouseIcon width={outerIconSize} height={outerIconSize} />
        <GraphicIconTextDiv>
          <Text type={textType}>Unternehmen</Text>
        </GraphicIconTextDiv>
      </GraphicIconDiv>

      <GraphicMiddleDiv>
        <Text type={textType}>Lohn- & Gehaltsdokumente</Text>
        <GraphicInnerIconDiv>
          <DocumentIcon width={innerIconSize} height={innerIconSize} />
        </GraphicInnerIconDiv>
        <ArrowIcon width={arrowWidth} />
        <GraphicInnerIconDiv>
          <CloudExchangeIcon width={innerIconSize} height={innerIconSize} />
        </GraphicInnerIconDiv>
        <Text type={textType}>einfach online zur Verfügung stellen</Text>
      </GraphicMiddleDiv>
      <GraphicIconDiv>
        <ArbeitnehmerOnlineBodyRightIcon
          width={outerIconSize}
          height={outerIconSize}
        />
        <GraphicIconTextDiv>
          <Text type={textType}>Mitarbeiter</Text>
        </GraphicIconTextDiv>
      </GraphicIconDiv>
    </GraphicDiv>
  )
}

const ArbeitnehmerOnlineBody = () => {
  return (
    <ServicesBoxBody>
      <RootDiv>
        <Text type={TextType.SubSubHeader}>
          <HeaderP>
            Arbeitnehmer online - Die digitale Zusammenarbeit zwischen
            Arbeitnehmer und Arbeitgeber
          </HeaderP>
        </Text>
        <Media greaterThanOrEqual="lg">
          <Graphic
            outerIconSize="100px"
            innerIconSize="40px"
            arrowWidth="300px"
            textType={TextType.Body}
          />
        </Media>
        <Media at="md">
          <Graphic
            outerIconSize="60px"
            innerIconSize="30px"
            arrowWidth="120px"
            textType={TextType.Small}
          />
        </Media>
        <Media at="sm">
          <Graphic
            outerIconSize="40px"
            innerIconSize="20px"
            arrowWidth="90px"
            textType={TextType.Small}
          />
        </Media>
        <Text type={TextType.Body}>
          Mit <ColoredSpan>Arbeinehmer online</ColoredSpan> stellen Sie Ihren
          Mitarbeitern wichtige Lohn- und Gehaltsdokumente einfach und sicher
          online zur Verfügung. Ihre Mitarbeiter können sich ihre Dokumente ganz
          unkompliziert selbst herunterladen – und das bei maximaler Sicherheit,
          Transparenz und Flexibilität: Papierverschwendung und Druckkosten
          gehören so der Vergangenheit an. Gehen Sie jetzt mit uns in die
          Zukunft der digitalen Lohn- und Gehaltsabrechnungen!
        </Text>
        <HeaderP>
          Gehen Sie mit uns in Richtung Zukunft. Unser Team berät Sie gerne!
        </HeaderP>
        <StyledUl>
          <StyledLi>
            <Text type={TextType.Body} bold>
              <ColoredSpan>Einfach:</ColoredSpan>
              <br />
            </Text>
            <Text type={TextType.Body}>
              Weniger Aufwand dank Lohn- und Gehaltsdokumenten online
            </Text>
          </StyledLi>
          <StyledLi>
            <Text type={TextType.Body} bold>
              <ColoredSpan>Flexibel:</ColoredSpan>
              <br />
            </Text>
            <Text type={TextType.Body}>
              Immer und überall Zugriff auf Lohn- und Gehaltsdokumente
            </Text>
          </StyledLi>
          <StyledLi>
            <Text type={TextType.Body} bold>
              <ColoredSpan>Unkompliziert:</ColoredSpan>
              <br />
            </Text>
            <Text type={TextType.Body}>
              Informationen einfach und sicher weitergeben
            </Text>
          </StyledLi>
          <StyledLi>
            <Text type={TextType.Body} bold>
              <ColoredSpan>Modern:</ColoredSpan>
              <br />
            </Text>
            <Text type={TextType.Body}>
              Weniger Starke Positionierung als Arbeitgeber am Puls der Zeit
            </Text>
          </StyledLi>
          <StyledLi>
            <Text type={TextType.Body} bold>
              <ColoredSpan>Vorrausschauend:</ColoredSpan>
              <br />
            </Text>
            <Text type={TextType.Body}>
              Kostensparend, innovativ, umweltschonend
            </Text>
          </StyledLi>
        </StyledUl>
      </RootDiv>
    </ServicesBoxBody>
  )
}

export default ArbeitnehmerOnlineBody
