import React from "react"
import Arrow from "./arrow-straight.svg"
import Document from "./document.svg"
import Right from "./arbeitnehmer-online-icon-right.svg"
import Cloud from "./cloud-exchange.svg"
import styled from "styled-components"

const StyledArrow = styled(Arrow)`
  width: ${props => props.width};
`

const StyledDocument = styled(Document)`
  width: ${props => props.width};
  height: ${props => props.height};
`
const StyledRight = styled(Right)`
  width: ${props => props.width};
  height: ${props => props.height};
`
const StyledCloud = styled(Cloud)`
  width: ${props => props.width};
  height: ${props => props.height};
`

interface Props {
  width: string
  height: string
}

export const ArrowIcon = (props: { width: string }) => (<StyledArrow width={props.width} />)
export const DocumentIcon = (props: Props) => (<StyledDocument height={props.height} width={props.width} />)
export const ArbeitnehmerOnlineBodyRightIcon = (props: Props) => (<StyledRight height={props.height} width={props.width} />)
export const CloudExchangeIcon = (props: Props) => (<StyledCloud height={props.height} width={props.width} />)
