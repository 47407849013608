import { useLocation } from "@reach/router"
import React, { useState } from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"

const RootDiv = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 20px;
    color: ${props => props.open ? "white" : props.theme.textColor};
    background: ${props => props.open ? props.theme.main : props.theme.second};
    width: 100%;
    box-shadow: ${props => props.theme.shadow};
    &:hover {
        box-shadow: ${props => props.theme.shadowHovered};
    }
    transition: box-shadow 0.2s;
`

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const BoxHeaderTextDiv = styled.div`
    text-align: center;
    margin: auto;
    font-weight: 500;
`


const ChildrenDiv = styled.div`
    max-height: ${props => props.open ? props.maxHeight : "0px"};
    overflow: hidden;
    transition: max-height 1s;
`

interface BoxProps {
    setOpen: () => void
    open: boolean;
    headerText: string;
    children: React.ReactNode
    icon?: [React.ReactNode, React.ReactNode]
    id?: string
    maxHeight: string
}

const UnfoldBox = (props: BoxProps) => {

    const unfold = () => {
        props.setOpen()
    }

    return (
        <RootDiv id={props.id} open={props.open} >
            <HeaderDiv onClick={unfold}>
                {props.icon && (props.open ? props.icon[0] : props.icon[1])}
                <BoxHeaderTextDiv>
                    <Text type={TextType.SubHeader}>
                        {props.headerText}
                    </Text>
                </BoxHeaderTextDiv>
                {props.icon && (props.open ? props.icon[0] : props.icon[1])}
            </HeaderDiv>
            <ChildrenDiv open={props.open} maxHeight={props.maxHeight} >
                {props.children}
            </ChildrenDiv>
        </RootDiv>
    )
}

const ListDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export interface ListItem {
    title: string
    icon?: [React.ReactNode, React.ReactNode]
    body: React.ReactNode
    id?: string
    maxHeight: string;
}

interface ListProps {
    initOpen?: number
    items: ListItem[]
}

const UnfoldBoxList = (props: ListProps) => {
    const { state } = useLocation()
    const defaultInit = state ? state.openIndex : -1
    const [open, setOpen] = useState(props.initOpen ? props.initOpen : defaultInit)

    console.log(`Gimme height: ${props.items.map(x => x.maxHeight)}`)

    const switchOpen: (index: number) => () => void
        = index => () => {
            setOpen(open === index ? -1 : index)
        }

    return (
        <ListDiv>
            {props.items.map((item, index) => (
                <UnfoldBox
                    id={item.id}
                    key={index}
                    headerText={item.title}
                    open={index === open}
                    setOpen={switchOpen(index)}
                    icon={item.icon}
                    maxHeight={item.maxHeight}>
                    {item.body}
                </UnfoldBox>
            ))}
        </ListDiv>

    )
}


export default UnfoldBoxList
