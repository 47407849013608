import React from "react"
import styled from "styled-components"
import ArbeitnehmerOnlineDark from "./arbeitnehmer-online-dark.svg"
import ArbeitnehmerOnlineWhite from "./arbeitnehmer-online-white.svg"

const IconWhite = styled(ArbeitnehmerOnlineWhite)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const IconDark = styled(ArbeitnehmerOnlineDark)`
    width: ${props => props.width};
    height: ${props => props.height};
`

const ArbeitnehmerOnlineIcon = ({ white, width, height }: { white?: boolean, width: string, height: string }) => (
    white ? <IconWhite width={width} height={height} /> : <IconDark width={width} height={height} />
)

export default ArbeitnehmerOnlineIcon
