import React from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"
import * as Icons from "../icons/unternehmenOnlineBodyIcons"
import ServicesBoxBody from "./servicesBoxBody"
import { laptop, tablet } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"

const HeaderP = styled.p`
  color: ${props => props.theme.main};
`

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const GraphicDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${tablet} {
    width: 70%;
  }
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`

const ArrowsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`

const GraphicIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GraphicIconTextDiv = styled.div`
  margin-top: 10px;
`

const GraphicArrowTextDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const Graphic = ({ arrowWidth, arrowHeight, iconSize, textType }) => {
  return (
    <GraphicDiv>
      <GraphicIconDiv>
        <Icons.HouseIcon height={iconSize} width={iconSize} />
        <GraphicIconTextDiv>
          <Text type={textType}>Unternehmen</Text>
        </GraphicIconTextDiv>
      </GraphicIconDiv>
      <ArrowsDiv>
        <Icons.ArrowRightIcon height={arrowHeight} width={arrowWidth} />
        <GraphicArrowTextDiv>
          <Text type={textType}>Digitaler Austausch von Belegen</Text>
        </GraphicArrowTextDiv>
        <Icons.ArrowLeftIcon height={arrowHeight} width={arrowWidth} />
      </ArrowsDiv>
      <GraphicIconDiv>
        <Icons.DeskIcon height={iconSize} width={iconSize} />
        <GraphicIconTextDiv>
          <Text type={textType}>Steuerberater</Text>
        </GraphicIconTextDiv>
      </GraphicIconDiv>
    </GraphicDiv>
  )
}

const StyledUl = styled.ul`
  margin: 0;
  padding-left: 20px;
  margin-top: 20px;
`

const StyledLi = styled.li`
  font-size: 14px;
  margin-bottom: 20px;
`

const ColoredSpan = styled.span`
  color: ${props => props.theme.main};
`

const UnternehmenOnlineServiceBoxBody = () => (
  <ServicesBoxBody>
    <RootDiv>
      <Text type={TextType.SubHeader}>
        <HeaderP>
          Unternehmen online - Mit moderner Onlinebuchführung in die Zukunft
        </HeaderP>
      </Text>
      <Media greaterThanOrEqual="lg">
        <Graphic
          arrowWidth="300px"
          arrowHeight="50px"
          iconSize="100px"
          textType={TextType.Body}
        />
      </Media>
      <Media at="md">
        <Graphic
          arrowWidth="120px"
          arrowHeight="25px"
          iconSize="60px"
          textType={TextType.Small}
        />
      </Media>
      <Media at="sm">
        <Graphic
          arrowWidth="90px"
          arrowHeight="20px"
          iconSize="40px"
          textType={TextType.Small}
        />
      </Media>
      <Text type={TextType.Body}>
        <p>
          Mit der Anwendung "Unternehmen online" stellen wir Ihnen ein modernes,
          digitales Instrument für eine zeitgemäße Buchführung zur Verfügung.
          Erfahren Sie, wie die Online-Buchführung Ihnen ein effizientes
          Belegmanagement und eine hohe Transparenz im Unternehmen ermöglicht.
          Wir bieten Ihnen mit dieser Anwendung:
        </p>
      </Text>
      <StyledUl>
        <StyledLi>
          <Text type={TextType.Body} bold>
            <ColoredSpan>Umfangreiche Analysemöglichkeiten</ColoredSpan>
          </Text>
          <br />
          <Text type={TextType.Body}>
            Führen Sie mit Leichtigkeit Auswertungen Ihrer Unternehmenszahlen
            durch, um Grundlagen für solide Managemententscheidungen zu
            schaffen.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text type={TextType.Body} bold>
            <ColoredSpan>Flexible Buchungsrythmen</ColoredSpan>
          </Text>
          <br />
          <Text type={TextType.Body}>
            Gerne buchen wir Ihre Belege in dem von Ihnen gewünschten Rythmus im
            System ein. Beispielsweise monatlich, wöchentlich oder auch täglich.
            Dabei bleibt es ganz Ihnen überlassen, welche
            Buchführungstätigkeiten in Ihrem Unternehmen verbleiben und welche
            unsere Kanzlei übernimmt.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text type={TextType.Body} bold>
            <ColoredSpan>
              Eine sichere Internetanwendung und ein hohes Maß an Kontrolle
            </ColoredSpan>
          </Text>
          <br />
          <Text type={TextType.Body}>
            Der Passwortgeschützte Zugang ermöglicht Ihnen das Daten- und
            Belegmanagement rund um die Uhr. Ein Datenstick gewährleistet
            höchste Sicherheit beim Zugriff auf die Anwendung. Mit diesem können
            Sie jedem Mitarbeiter individuelle Rechte zuteilen, um festzulegen,
            auf welche Daten jeweils zugegriffen werden kann.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text type={TextType.Body} bold>
            <ColoredSpan>Eine einfache Belegführung</ColoredSpan>
          </Text>
          <br />
          <Text type={TextType.Body}>
            Digitalisieren Sie Ihre Belege durch einfaches Scannen, auch mit dem
            Smartphone und befreien Sie sich von lästigen Pendelordnern zwischen
            Unternehmen und Steuerberater.
          </Text>
        </StyledLi>
      </StyledUl>
      <Text type={TextType.Body}>
        Gerne beraten wir Sie zu diesem Thema und erarbeiten gemeinsam mit Ihnen
        einen Prozess für eine lückenlose und überzeugende Buchführung.
      </Text>
    </RootDiv>
  </ServicesBoxBody>
)

export default UnternehmenOnlineServiceBoxBody
