import React from "react"
import styled from "styled-components"
import ServicesBoxBody from "./servicesBoxBody"
import Text, { TextType } from "./text"

const HeaderP = styled.p`
  color: ${props => props.theme.main};
`

const SteuerberatungServiceBoxBody = () => (
  <ServicesBoxBody>
    <Text type={TextType.SubSubHeader}>
      <HeaderP>Steuerberatung</HeaderP>
    </Text>
    <Text type={TextType.Body}>
      <p>
        Die stetigen Änderungen im Steuerrecht erfordern laufende Updates und
        umfangreiches Experten Know-how. Diese Anpassungen beeinflussen
        unmittelbar Ihr finanzielles Ergebnis. Deshalb ist es um so wichtiger,
        das Fachwissen einer breit aufgestellten Kanzlei in Anspruch zu nehmen.
      </p>
      <p>Jede Privatperson und jedes Unternehmen ist individuell!</p>
      <p>
        Genau aus diesem Grund gehen wir auf Ihre ganz persönliche Situation ein
        und entwickeln gemeinsam optimale Lösungen, die immer vor dem
        Hintergrund der aktualisierten Steuergesetze erarbeitet werden.
      </p>
      <p>
        Die Unterstützung bei der privaten Finanz- und Vermögensplanung,
        umfassende Betriebs- und Wirtschaftsberatung, Neu- und Umgründungen,
        Sanierungsberatung sowie die Unterstützung bei Finanzgesprächen mit
        Banken ergänzen unser individuelles Angebot.
      </p>
    </Text>
    <Text type={TextType.SubSubHeader}>
      <HeaderP>Jahresabschluss</HeaderP>
    </Text>
    <Text type={TextType.Body}>
      <p>
        Sie benötigen kompetente Hilfe bei der Erstellung Ihres
        Jahresabschlusses? Bei uns sind Sie richtig! Für eine ordnungsgemäße
        Erstellung des Jahresabschlusses ist umfangreiches Wissen zu den
        handelsrechtlichen und steuerrechtlichen Vorschriften erforderlich.
      </p>
      <p>
        Die steuerverschärfende Rechtslage macht diese Aufgabe zusätzlich
        komplexer. Wir stellen Ihnen unser Know-how gerne zur Verfügung!
      </p>
      <p>
        <Text type={TextType.Body} bold>
          Unser Know-how, Ihr Vorteil
        </Text>
      </p>
      <p>Gerne unterstützten wir Sie bei diesen umfangreichen Aufgaben.</p>
    </Text>
  </ServicesBoxBody>
)

export default SteuerberatungServiceBoxBody
