import React from "react"
import styled from "styled-components"
import ServicesBoxBody from "./servicesBoxBody"
import Text, { TextType } from "./text"

const HeaderP = styled.p`
  color: ${props => props.theme.main};
`

const BuchhaltungBody = () => (
  <ServicesBoxBody>
    <Text type={TextType.SubSubHeader}>
      <HeaderP>Finanzbuchhaltung</HeaderP>
    </Text>
    <Text type={TextType.Body}>
      <p>
        Die Buchführung ist Basis für ein ordnungsgemäßes Rechnungswesen und ein
        funktionierendes Controlling. Sie ist damit ein Fundament für eines der
        wichtigsten Steuerungsinstrumente eines Unternehmens.
      </p>
      <p>
        Die Auslagerung dieser Aufgaben an eine kompetente Steuerkanzlei ist für
        Sie als Unternehmer eine große Entlastung. Neben der klassischen
        Buchhaltung bieten wir Ihnen auch eine für Verwendungsnachweise
        erforderliche, an Ihr Unternehmen angepasste Kostenrechnung an.
      </p>
      <p>
        Die Finanzbuchhaltung ist ein zeitintensiver Arbeitsbereich. Wir
        unterstützen Sie gerne mit unserem Know-how. Durch das Outsourcing
        erfahren Sie zeitliche Entlastung und profitieren von unserem
        umfassenden Reporting.
      </p>
      <p>
        Unterschiedliche Unternehmen brauchen verschiedene Lösungen. Aus diesem
        Grund muss auch der Umfang des Rechnungswesens an Ihr Unternehmen und
        die jeweilige Gewinnermittlungsart angepasst werden. Die Grundlage dafür
        bilden die besonderen Anforderungen Ihres Unternehmens unter Beachtung
        der gesetzlichen Vorschriften.
      </p>
      <p>
        Mit der Möglichkeit „Unternehmen online“ von DATEV steht Ihnen ein
        neues, zukunftsfähiges Instrument für moderne Buchführung zur Verfügung.
        Gehen Sie online und profitieren Sie von höherer Unternehmenstransparenz
        und effizientem Belegmanagement rund um die Uhr. Mehr hierzu können Sie
        dem Unterpunkt „Unternehmen online“ entnehmen.
      </p>
    </Text>
    <Text type={TextType.SubSubHeader}>
      <HeaderP>Lohnbuchhaltung</HeaderP>
    </Text>
    <Text type={TextType.Body}>
      <p>
        Lohn- und Gehaltsabrechnungen sind besonders komplex, da sie sowohl
        Fragen des Lohnsteuerrechts als auch arbeits- und
        sozialversicherungsrechtliche Aspekte beinhalten.
      </p>
      <p>
        Zu unserem Leistungsumfang zählen unter anderem die An- und Abmeldungen
        bei der Krankenkasse, die Berechnung laufender Lohnabgaben sowie die
        laufende Lohn- und Gehaltsabrechnung.
      </p>
      <p>
        Ergänzend beraten wir in Sachen Lohn- und Gehaltszahlungen, begleiten
        Lohnsteuer- und Sozialversicherungsprüfungen und helfen bei der Klärung
        steuer- und sozialversicherungsrechtlicher Zweifelsfragen.
      </p>
    </Text>
  </ServicesBoxBody>
)

export default BuchhaltungBody
