import React from "react"
import styled from "styled-components"
import UnternehmenOnlineHouse from "./unternehmen-online-house.svg"
import UnternehmenOnlineDesk from "./unternehmen-online-desk.svg"
import UnternehmenOnlineArrowRight from "./unternehmen-online-arrow-right.svg"
import UnternehmenOnlineArrowLeft from "./unternehmen-online-arrow-left.svg"



const StyledHouseIcon = styled(UnternehmenOnlineHouse)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const StyledDeskIcon = styled(UnternehmenOnlineDesk)`
    width: ${props => props.width};
    height: ${props => props.height};
`

const StyledArrowRightIcon = styled(UnternehmenOnlineArrowRight)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const StyledArrowLeftIcon = styled(UnternehmenOnlineArrowLeft)`
    width: ${props => props.width};
    height: ${props => props.height};
`

interface Props {
    width: string
    height: string
}


export const HouseIcon = (props: Props) => (
    <StyledHouseIcon height={props.height} width={props.width} />
)

export const DeskIcon = (props: Props) => (
    <StyledDeskIcon height={props.height} width={props.width} />
)

export const ArrowRightIcon = (props: Props) => (
    <StyledArrowRightIcon height={props.height} width={props.width} />
)

export const ArrowLeftIcon = (props: Props) => (
    <StyledArrowLeftIcon height={props.height} width={props.width} />
)
