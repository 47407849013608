import React from "react"
import styled from "styled-components"
import UnternehmenOnlineWhite from "./unternehmen-online-white.svg"
import UnternehmenOnlineDark from "./unternehmen-online-dark.svg"

const IconWhite = styled(UnternehmenOnlineWhite)`
    width: ${props => props.width};
    height: ${props => props.height};
`
const IconDark = styled(UnternehmenOnlineDark)`
    width: ${props => props.width};
    height: ${props => props.height};
`

const UnternehmenOnlineIcon = ({ white, height, width }: { white?: boolean, height: string, width: string }) => (
    white ? <IconWhite width={width} height={height} /> : <IconDark width={width} height={height} />
)

export default UnternehmenOnlineIcon
