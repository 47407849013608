import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ContentDiv from "../components/contentDiv"
import Layout from "../components/layout"
import PageImage from "../components/pageImage"
import PageSection from "../components/pageSection"
import UnfoldBoxList from "../components/unfoldBoxList"
import { SteuerberatungIcon } from "../icons/steuerberatunIcon"
import { UnternehmensberatungIcon } from "../icons/unternehmensberatungIcon"
import { BuchhaltungIcon } from "../icons/buchhaltungIcon"
import FirstSection from "../components/firstSection"
import styled from "styled-components"
import SteuerberatungServiceBoxBody from "../components/SteuerberatungServiceBoxBody"
import UnternehmensberatungServiceBoxBody from "../components/UnternehmensberatunServiceBoxBody"
import UnternehmenOnlineIcon from "../icons/unternehmenOnlineIcon"
import ArbeitnehmerOnlineIcon from "../icons/arbeitnehmerOnlineIcon"
import UnternehmenOnlineServiceBoxBody from "../components/unternehmenOnlineServiceBoxBody"
import ArbeitnehmerOnlineBody from "../components/arbeitnehmerOnlineBody"
import BuchhaltungBody from "../components/buchhaltungBody"
import { laptop, tablet, phone } from "../util/mediaQueries"
import { Media } from "gatsby-plugin-fresnel"
import SEO from "../components/seo"

const IconDiv = styled.div`
  width: 40px;
  height: 40px;
`

const ServicesDiv = styled.div``

const maxHeightsLaptop = ["830px", "590px", "1020px", "1090px", "1070px"]
const maxHeightsTablet = ["1100px", "750px", "1020px", "1540px", "1450px"]
const maxHeightsPhone = ["1400px", "1020px", "2100px", "1920px", "1500px"]

const addIconDiv = (tup: [React.ReactNode, React.ReactNode]) => [
  <IconDiv>{tup[0]}</IconDiv>,
  <IconDiv>{tup[1]}</IconDiv>,
]

const services = (maxHeights: string[], iconSize: string) => {
  return [
    {
      title: "Steuerberatung & Jahresabschluss",
      id: "steuerberatung",
      body: <SteuerberatungServiceBoxBody />,
      icon: addIconDiv([
        <SteuerberatungIcon white width={iconSize} height={iconSize} />,
        <SteuerberatungIcon width={iconSize} height={iconSize} />,
      ]),
      maxHeight: maxHeights[0],
    },
    {
      title: "Unternehmensberatung",
      id: "unternehmensberatung",
      body: <UnternehmensberatungServiceBoxBody />,
      icon: addIconDiv([
        <UnternehmensberatungIcon white width={iconSize} height={iconSize} />,
        <UnternehmensberatungIcon width={iconSize} height={iconSize} />,
      ]),
      maxHeight: maxHeights[1],
    },
    {
      title: `Finanz- & \n Lohnbuchhaltung`,
      id: "buchhaltung",
      body: <BuchhaltungBody />,
      icon: addIconDiv([
        <BuchhaltungIcon white width={iconSize} height={iconSize} />,
        <BuchhaltungIcon width={iconSize} height={iconSize} />,
      ]),
      maxHeight: maxHeights[2],
    },
    {
      title: "Unternehmen online",
      id: "unternehmen-online",
      body: <UnternehmenOnlineServiceBoxBody />,
      icon: [
        <UnternehmenOnlineIcon white width={iconSize} height={iconSize} />,
        <UnternehmenOnlineIcon width={iconSize} height={iconSize} />,
      ],
      maxHeight: maxHeights[3],
    },
    {
      title: "Arbeitnehmer online",
      id: "arbeitgeber-online",
      body: <ArbeitnehmerOnlineBody />,
      icon: [
        <ArbeitnehmerOnlineIcon white width={iconSize} height={iconSize} />,
        <ArbeitnehmerOnlineIcon width={iconSize} height={iconSize} />,
      ],
      maxHeight: maxHeights[4],
    },
  ]
}

const Leistungen = () => {
  return (
    <Layout>
      <SEO lang="de" title="Leistungen" />
      <PageImage>
        <Media greaterThanOrEqual="md">
          <StaticImage
            src="../images/Titelbild_Leistungen_PC.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
        <Media at="sm">
          <StaticImage
            src="../images/Titelbild_Leistungen_Smartp.jpeg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Image"
            objectFit="contain"
            layout="fullWidth"
          />
        </Media>
      </PageImage>
      <FirstSection headerText="Unser Leistungsangebot">
        <p>
          Jeder Mandant hat seinen individuellen Beratungsbedarf. Ziel unserer
          Berufsausübung ist es, diesem durch Fachkompetenz, durch Einsatz
          qualifizierter Mitarbeiter/innen gerecht zu werden. Dabei ist uns die
          persönliche Betreuung besonders wichtig.
        </p>
        <p>
          Zu unseren Mandanten gehören Freiberufler verschiedenster
          Fachrichtungen, kleinere und mittlere Handwerks-, Produktions-,
          Handels- und Dienstleistungsunternehmen, Vereine und Berufsverbände
          sowie Privatpersonen.
        </p>
      </FirstSection>
      <PageSection>
        <ContentDiv>
          <ServicesDiv>
            <Media greaterThanOrEqual="lg">
              <UnfoldBoxList items={services(maxHeightsLaptop, "40px")} />
            </Media>
            <Media at="md">
              <UnfoldBoxList items={services(maxHeightsTablet, "40px")} />
            </Media>
            <Media at="sm">
              <UnfoldBoxList items={services(maxHeightsPhone, "35px")} />
            </Media>
          </ServicesDiv>
        </ContentDiv>
      </PageSection>
    </Layout>
  )
}

export default Leistungen
