import React from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"

const UpperLine = styled.div`
    margin-right: 20px;
    margin-left: 20px;
    border-top: solid black 2px;
    height: 10px;
`
const BottomLine = styled.div`
    border-bottom: solid black 2px;
`
const LinesDiv = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    alignt-items: center;
    margin: auto;
    margin-bottom: 20px;
`
const Lines = () => (
    <LinesDiv>
        <UpperLine />
        <BottomLine />
    </LinesDiv>
)

const BodyDiv = styled.div`
    padding: 20px;
    background: white;
    color: ${props => props.theme.textColor};
    border-radius: 0px 0px 10px 10px;
`

interface Props {
    children: React.ReactNode
}

const RootDiv = styled.div`
    padding: 3px;
`

const ServicesBoxBody = (props: Props) => (
    <RootDiv>
        <BodyDiv>
            <Text type={TextType.Body}>
                {props.children}
            </Text>
        </BodyDiv>
    </RootDiv>
)

export default ServicesBoxBody
